import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Layout from '../layouts/index';
import translationsEn from '../locales/en.json';
import translationsEs from '../locales/es.json';

const NotFoundPage = () => {
  const [locale, setLocale] = useState('es');

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLocale = localStorage.getItem('locale') || 'es';
      setLocale(storedLocale);
    }

    const handleLanguageChange = (event) => {
      setLocale(event.detail);
    };

    window.addEventListener('languageChanged', handleLanguageChange);
    return () => window.removeEventListener('languageChanged', handleLanguageChange);
  }, []);

  const data = useStaticQuery(graphql`
    query {
      site: datoCmsSite {
        faviconMetaTags {
          tags
        }
        globalSeo {
          siteName
        }
      }
    }
  `);

  const translations = locale === 'es' ? translationsEs : translationsEn;

  return (
    <Layout
      site={data.site}
      seo={{ ...data.site.globalSeo, ...data.site.faviconMetaTags }}
      lang={locale}
      title={"404 - OS DO CASAL"}
      description={"La página solicitada no ha devuelto resultados"}
      noIndex={true}
    >
      <div className="Not-found">
        <h2 className="Not-found__name">{translations['404']}</h2>
        <p>{translations['not-found']}</p>
        <p><Link to={`/`}>{translations['go-to-main-page']}</Link></p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
